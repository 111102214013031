import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/public/assets/images/default-chat-cover.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/public/assets/images/logo-white.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/ai-bot/[chatbotIdOrSlug]/content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/app/ai-bot/[chatbotIdOrSlug]/password-protection-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MessengerProvider"] */ "/vercel/path0/apps/web/src/context/messengerContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/dist/client/link.js");
