import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export default function useWindowSize(): number {
  const [width, setWidth] = useState<number>();

  const onResize = () => {
    const width = window.innerWidth;
    setWidth(width);
  };

  const throttledOnResize = useCallback(throttle(onResize, 300), []);

  useEffect(() => {
    window.addEventListener('resize', throttledOnResize);
    throttledOnResize();

    return () => {
      window.removeEventListener('resize', throttledOnResize);
      throttledOnResize.cancel();
    };
  }, []);

  return width;
}
