'use client';

import { useState } from 'react';

import { useFormStatus } from 'react-dom';

import Button from '@/components/button.component';
import LockIconPincode from '@/components/icons/lock-icon-pincode';

interface PasswordProtectionWrapperProps {
  onSubmit: (formData: FormData) => Promise<string | void>;
}

function SubmitButton() {
  const { pending } = useFormStatus();

  return (
    <Button type="submit" disabled={pending} isLoading={pending} variant="dark">
      Continue
    </Button>
  );
}

const PasswordProtectionWrapper: React.FC<PasswordProtectionWrapperProps> = ({
  onSubmit,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (formData: FormData) => {
    setError(null);
    const result = await onSubmit(formData);
    if (typeof result === 'string') {
      setError(result);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-white">
      <div className="w-full max-w-sm rounded-lg bg-white px-9 py-6 shadow-md">
        <div className="mb-3 flex justify-center">
          <div className="rounded-full bg-gray-200 p-3">
            <LockIconPincode className="h-6 w-6 text-gray-600" />
          </div>
        </div>
        <h2 className="mb-2 text-center text-2xl text-gray-900">
          Protected chat
        </h2>
        <p className="mb-[20px] text-center text-sm text-gray-500">
          Please enter the password provided to you for this page.
        </p>
        <form action={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="mb-2 block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
              placeholder="Enter password for Full Page Chat"
              required
            />
          </div>
          {error && <p className="mb-4 text-sm text-red-600">{error}</p>}
          <SubmitButton />
        </form>
      </div>
    </div>
  );
};

export default PasswordProtectionWrapper;
